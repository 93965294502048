import React from 'react'
import { Link } from 'gatsby'
import './Header.scss'
import Container from '../Container'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navOpen: false
    }
  }

  showMobileNav = event => {
    const navOpen = this.state.navOpen
    this.setState({
      navOpen: !navOpen
    })
  }

  render () {
    return (
      <header 
        className={
          ('site-header shadowed') + 
          (this.state.navOpen ? ' nav-mobile-open': '')
        }
      >
        <Container>
          <div className="site-branding">
            <Link to={'/'} className="site-title">
              {this.props.siteMeta.name}
            </Link>
          </div>
          <nav className="site-nav">
            <ul className="nav-list">
              <li className="nav-item">
                <Link to="/blog/">Blog</Link>
              </li>
              <li className="nav-item">
                <a href="https://www.base64encoder.io" target="_blank">Base64Encoder.io</a>
              </li>
            </ul>
          </nav>
          <button className="nav-opener" onClick={this.showMobileNav}>
            <span className="menu"></span>
          </button>
          <nav className="nav-mobile">
            <ul className="nav-mobile-list">
              <li className="nav-mobile-item">
                <Link to="/blog/" className="nav-mobile-link">Blog</Link>
              </li>
              <li className="nav-mobile-item">
                <a href="https://www.base64encoder.io" className="nav-mobile-link" target="_blank">
                Base64Encoder.io
                </a>
              </li>
            </ul>
          </nav>
        </Container>
      </header>
    )
  }
}

export default Header
