import React from 'react'
import { Link } from 'gatsby'
import Container from '../Container'
import './Footer.scss'

class Footer extends React.Component {
  render () {
    return (
      <footer className="site-footer">
        <Container>
          <div className="footer-hero footer-col">
            <div className="footer-branding">
              <Link className="footer-title" to="/">{this.props.siteMeta.name}</Link>
            </div>
            <span className="footer-copyright">Copyright &copy; 2018 {this.props.siteMeta.name}</span>
            <Link className="footer-privacy" to="/privacy-policy/">Privacy Policy</Link>
          </div>
          <div className="footer-col footer-links">
            <ul className="footer-list">
              <li className="footer-item"><Link to="/">Home</Link></li>
              <li className="footer-item"><Link to="/blog/">Blog</Link></li>
              <li className="footer-item"><Link to="/about/">About</Link></li>
              <li className="footer-item"><Link to="/about/#how-can-i-contact-you">Contact</Link></li>
            </ul>
          </div>          
        </Container>
        <Container>
          <div className="tool-links">
            <ul className="footer-list">
              <li className="footer-item"><a href="https://www.urlencoder.io">URL Encoder</a></li>
              <li className="footer-item"><a href="https://www.urldecoder.io">URL Decoder</a></li>
              <li className="footer-item"><a href="https://www.jsonformatter.io">JSON Formatter</a></li>
              <li className="footer-item"><a href="https://www.asciitable.xyz">ASCII Table</a></li>
              <li className="footer-item"><a href="https://www.qrcodebit.com">QRCodeBit</a></li>
              <li className="footer-item"><a href="https://www.callicoder.com">CalliCoder</a></li>
            </ul>
          </div>
        </Container>
      </footer>
    )
  }
}

export default Footer
